html {
    min-width: 320px;
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body * {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    min-height: 100%;
    display: flex;
    flex: 1 1 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #root {
        height: 100%;
    }
}

.dayAheadModal .ant-modal-content {
  border-radius: 30px;
  padding: 46px 22px 56px 22px;
  max-width: 325px;
}

.copiedUrlModal .ant-modal-content {
  border-radius: 30px;
  padding: 46px 22px 56px 22px;
  max-width: 325px;
}

.app_3g35f {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  flex-grow: 1;
  background-color: #F6F8FB;
  width: 100%;
}
.app_3g35f > .main_3vK6j {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
.app_3g35f > .mobileMain_1hlyl {
  display: flex;
  display: -webkit-flexbox;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  background-image: url("/background-image.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.ant-notification {
  width: auto;
  max-width: 600px;
  overflow-wrap: break-word;
}
.notification_1BHVl .ant-notification-notice-message {
  font-weight: 500;
  font-size: 16px;
}
.notification_1BHVl .ant-notification-notice-description {
  font-size: 16px;
  margin: 0 50px 0 60px;
}
.notification_1BHVl .ant-notification-notice-message {
  margin: 0 50px 0 60px;
}
.notification_1BHVl .ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notification_1BHVl .ant-notification-notice-with-icon > i {
  font-size: 32px;
}

@media only screen and (max-width: 719px), only screen and (min-width: 720px) and (max-width: 959px) {
  .container_ga0Aj {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 400px;
  }
}
@media only screen and (min-width: 960px) {
  .container_ga0Aj {
    top: 50%;
    left: 50%;
    position: fixed;
    -webkit-transform: translate(-50%, -75%);
            transform: translate(-50%, -75%);
  }
}
.ant-spin-dot_cUBeW {
  width: 40px;
  height: 40px;
}
.ant-spin-dot-item_34mC5 {
  width: 20px;
  height: 20px;
}
.ant-spin-nested-loading_3xjmt > div > .ant-spin_3cCjC {
  max-height: none;
  min-height: 100%;
}

@media only screen and (min-width: 960px) {
  .root_3_3Fq {
    display: flex;
    display: -webkit-flexbox;
    flex: 1 1 100%;
    flex-direction: column;
  }
  .root_3_3Fq > div {
    height: 100%;
    overflow: auto;
  }
}
@media only screen and (min-width: 960px) and screen and (-ms-high-contrast: active), only screen and (min-width: 960px) and (-ms-high-contrast: none) {
  .root_3_3Fq {
    flex: 1 0 auto;
  }
}
@media only screen and (min-width: 960px) and screen and (-ms-high-contrast: active), only screen and (min-width: 960px) and (-ms-high-contrast: none) {
  .root_3_3Fq > div {
    overflow: hidden;
  }
}

.cookieConsent {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffb12e;
    font-size: calc(10px + 0.8vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    z-index: 100000;
}

.cookieConsent > button {
    width: 168px;
    height: calc(40px + 1vw);
    max-height: 54px;
    border-radius: 36px;
    color: #707070;
    background-color: #FFFFFF;
}

.cookie-consent-content {
    width: 100%;
    color: #FFFFFF;
    background-color: #FFB12E;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.cookie-consent-content > div {
    width: 100%;
    max-width: 720px
}

.cookie-consent-content a {
    color: #FFFFFF;
    text-decoration: underline;
}

@media screen and (max-width: 1920px) {

    .cookie-consent-content > div {
        max-width: 95vw;
    }

    .cookieConsent > button {
        font-size: calc(10px + 1vw);
    }

}

@media screen and (min-width: 1920px) {

    .cookie-consent-content > div {
        max-width: 1024px;
    }

    .cookieConsent > button {
        font-size: 30px;
    }

}
.mainContainer_3p0et {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 15px;
  border-radius: 20px;
  background-color: white;
  color: #647B93;
  font-size: 16px;
  box-shadow: 5px 5px #E8E8E6;
}
.mainContainer_3p0et .warningContainer_1sMJ6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-weight: 500;
  margin-bottom: 40px;
}
.mainContainer_3p0et .warningContainer_1sMJ6 .warningIcon_3DaLp {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.mainContainer_3p0et .warningContainer_1sMJ6 .warningText_1Qjwh {
  font-weight: 500;
}
.mainContainer_3p0et .textContainer_1t65U {
  text-align: center;
  font-weight: 500;
  max-width: 400px;
}
.mainContainer_3p0et .storeLinksContainer_1EUxJ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: 8px;
}
.mainContainer_3p0et .storeLinksContainer_1EUxJ .storeLinkGoogle_2OFMJ img {
  width: 180px;
}
.mainContainer_3p0et .storeLinksContainer_1EUxJ .storeLinkApple_Wcq4H img {
  width: 158px;
  height: 47px;
}
@media screen and (min-width: 660px) {
  .mainContainer_3p0et {
    width: 600px;
  }
}

