@import 'src/styles/resolutions';

.root {
    @media @desktop {
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        display: -webkit-flexbox;
        flex: 1 1 100%;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex: 1 0 auto;
        }
        flex-direction: column;

        > div {
            height: 100%;
            overflow: auto;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                overflow: hidden;
            }
        }
    }
}