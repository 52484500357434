@import "src/styles/resolutions";

:global {
    .ant-notification {
        width: auto;
        max-width: 600px;
        overflow-wrap: break-word;
    }
}

.notification {
    :global {

        .ant-notification-notice-message {
            font-weight: 500;
            font-size: 16px;
        }

        .ant-notification-notice-description {
            font-size: 16px;
            margin: 0 50px 0 60px;
        }

        .ant-notification-notice-message {
            margin: 0 50px 0 60px;
        }

        .ant-notification-notice-with-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > i {
                font-size: 32px;
            }
        }
    }
}