.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 15px;
  border-radius: 20px;
  background-color: white;
  color: #647B93;
  font-size: 16px;
  box-shadow: 5px 5px #E8E8E6;

  .warningContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-weight: 500;
    margin-bottom: 40px;

    .warningIcon {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }

    .warningText {
      font-weight: 500;
    }
  }

  .textContainer {
    text-align: center;
    font-weight: 500;
    max-width: 400px
  }

  .storeLinksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-left: 8px;
  
    .storeLinkGoogle {
      img {
        width: 180px;
      }
    }
    .storeLinkApple {
      img {
        width: 158px;
        height: 47px;
      }
    }
  }
}

@media screen and (min-width: 660px) {
  .mainContainer {
    width: 600px;
  }
}