.cookieConsent {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffb12e;
    font-size: calc(10px + 0.8vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    z-index: 100000;
}

.cookieConsent > button {
    width: 168px;
    height: calc(40px + 1vw);
    max-height: 54px;
    border-radius: 36px;
    color: #707070;
    background-color: #FFFFFF;
}

.cookie-consent-content {
    width: 100%;
    color: #FFFFFF;
    background-color: #FFB12E;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.cookie-consent-content > div {
    width: 100%;
    max-width: 720px
}

.cookie-consent-content a {
    color: #FFFFFF;
    text-decoration: underline;
}

@media screen and (max-width: 1920px) {

    .cookie-consent-content > div {
        max-width: 95vw;
    }

    .cookieConsent > button {
        font-size: calc(10px + 1vw);
    }

}

@media screen and (min-width: 1920px) {

    .cookie-consent-content > div {
        max-width: 1024px;
    }

    .cookieConsent > button {
        font-size: 30px;
    }

}