html {
    min-width: 320px;
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
}

body * {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    min-height: 100%;
    display: flex;
    flex: 1 1 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #root {
        height: 100%;
    }
}

.dayAheadModal .ant-modal-content {
  border-radius: 30px;
  padding: 46px 22px 56px 22px;
  max-width: 325px;
}

.copiedUrlModal .ant-modal-content {
  border-radius: 30px;
  padding: 46px 22px 56px 22px;
  max-width: 325px;
}
