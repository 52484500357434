@import "src/styles/resolutions";

.app {
    display: flex;
    display: -webkit-flex;
    display: -webkit-flexbox;
    flex-direction: column;
    flex-grow: 1;
    background-color: #F6F8FB;
    width: 100%;

    > .main {
        display: flex;
        display: -webkit-flex;
        display: -webkit-flexbox;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
    }

    > .mobileMain {
        display: flex;
        display: -webkit-flex;
        display: -webkit-flexbox;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        background-image: url("/background-image.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
}